@tailwind components;
@tailwind utilities;
.image-shadow::before {
  content: "";
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.underline-link {
  transition: all ease-in-out 0.25s !important;
  position: relative;
  display: inline-block !important;
  background-color: transparent;
  border: 0px !important;
  border-radius: 0 !important;
  color: rgba(0, 0, 0, 0.5) !important;
  padding: 0 !important;
  font-size: 1rem !important;
  text-transform: uppercase !important;
  border-bottom: 3px solid rgba(0, 0, 0, 0.5) !important;
}
header.reverse-scroll.sticky-header.header-appear #white-logo {
  filter: invert(1) brightness(0) !important;
}
.mobile-logo {
  filter: invert(1) brightness(0) !important;
}
header.reverse-scroll.sticky-header.header-appear .default-logo {
  visibility: visible !important;
  opacity: 1 !important;
}

// .underline-link::before {
//     content: "";
//     position: absolute;
//     top: 100%;
//     left: 0;
//     width: 100%;
//     height: 2px;
//     background-color:rgba(0, 0, 0, 0.5) ;
//     transform: scaleX(0);
//     transform-origin: left;
//     transition: transform 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
// }
// .underline-link:hover::before,
// .underline-link.active::before {
//     transform: scaleX(1);
//     background-color: rgba(0, 0, 0, 0.5);
// }

.underline-link:focus-visible,
.underline-link:hover {
  color: rgba(0, 0, 0, 0.5) !important;
  background-color: transparent !important;
  // border: 0px;
  box-shadow: unset !important;
  color: rgba(0, 0, 0, 0.5) !important;
  transform: translateY(-1px) !important;
}
#etisalat-logo {
  width: 150px !important;
  height: 60px !important;
  margin-top: 1.8rem !important;
}
@media (max-width: 768px) {
}

.underline-item {
  transition: all ease-in-out 0.25s !important;
  position: relative !important;
  display: inline-block !important;
  width: fit-content !important;
}

.underline-item::before {
  content: "";
  position: absolute !important;
  top: 100% !important;
  left: 0 !important;
  width: 100% !important;
  height: 2px !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  transform: scaleX(0) !important;
  transform-origin: left !important;
  transition: transform 0.3s cubic-bezier(0.2, 0.8, 0.2, 1) !important;
}
.underline-item:hover::before,
.underline-item.active::before {
  transform: scaleX(1) !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.underline-item:hover {
  color: rgba(0, 0, 0, 0.5) !important;
  text-decoration: none !important;
}
